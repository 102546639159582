import React, { useContext, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Link, navigate } from "gatsby";
import "./index.css";
import Phone from "../../Common/PhoneInput";
import { isBrowser } from "../../../utils/general";
import { LanguageContext } from "../../../lang";

const PersonalInformation = ({ onSelect, onBack }) => {
  const {
    translations: { personalInfo, bookingSteps },
  } = useContext(LanguageContext);
  const [companyDetails, setcompanyDetails] = useState(false);
  const {
    firstNameRequired,
    lastNameRequired,
    emailRequired,
    phoneRequired,
    houseNoRequired,
    firstNamePlaceHolder,
    lastNamePlaceHolder,
    emailPlaceHolder,
    houseNoPlaceHolder,
    journeyTypePlaceHolder,
    journeyTypeRequired,
    buttonText,
    formTitle,
    paymentTypeText,
    cashText,
    onlineText,
    oneWayPlaceholder,
    returnPlaceholder,
    notes,
    textareaPlaceholder,
  } = personalInfo;
  const { back2 } = bookingSteps;
  const schema = Yup.object().shape({
    fname: Yup.string().required(firstNameRequired),
    lname: Yup.string().required(lastNameRequired),
    email: Yup.string().email().required(emailRequired),
    phone: Yup.string().required(phoneRequired),
  });
  console.log("textareaPlaceholder", textareaPlaceholder);

  const companyDetialshandel = () => {
    setcompanyDetails({ companyDetails: !this.state.companyDetails });
  };

  const handleSubmit = (values) => {
    const { fname, lname, phone, email, houseno, notes } = values;
    const info = {
      custFullName: fname + " " + lname,
      custPhone: phone,
      custEmail: email,
      custHouseNo: houseno,
      custNotes: notes,
    };
    onSelect(info);
  };

  return (
    <section id="book-form">
      <div className="container">
        <div className="row d-flex justify-content-center mb-3">
          <div className="col-12 ts-personal-details-wrapper p-4">
            <Formik
              initialValues={{
                fname: "",
                lname: "",
                email: "",
                phone: "",
                notes: "",
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values, handleSubmit, setFieldValue }) => (
                <Form>
                  <div className="form-row">
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        name="fname"
                        className="form-control"
                        placeholder={firstNamePlaceHolder}
                      />
                      {errors.fname && touched.fname ? (
                        <div className="bg-danger my-1 p-0 col-12 rounded">
                          <p className="text-white p-1 m-0">{errors.fname}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-6 mt-tab-mob">
                      <Field
                        type="text"
                        name="lname"
                        className="form-control "
                        placeholder={lastNamePlaceHolder}
                      />
                      {errors.lname && touched.lname ? (
                        <div className="bg-danger my-1 p-0 col-12 rounded">
                          <p className="text-white p-1 m-0">{errors.lname}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row mt-4">
                    <div className="col-lg-12">
                      <Field
                        type="email"
                        name="email"
                        className="form-control "
                        placeholder={emailPlaceHolder}
                      />
                      {errors.email && touched.email ? (
                        <div className="bg-danger my-1 p-0 col-12 rounded">
                          <p className="text-white p-1 m-0">{errors.email}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row mt-4">
                    <div className="col-lg-12">
                      <Field name="phone" component={Phone} />
                    </div>
                  </div>
                  <div className="form-row mt-4">
                    {/* <div className="col-lg-12">{notes}</div> */}
                    <div className="col-lg-12">
                      <Field
                        name="notes"
                        component="textarea"
                        placeholder={textareaPlaceholder}
                        className="textareaField"
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center mt-5 mb-3">
                    <div className="col-12 ts-form-btn-wrapper">
                      <div className="row">
                        <div
                          className="col-6 p-0"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={onBack}
                        >
                          <i className="fa fa-arrow-left ts-form-back-arrow"></i>
                          <span className="return-btn-text">{back2}</span>
                        </div>
                        <div className="col-6 p-2">
                          <button
                            className="ts-form-next-link py-2 text-white border-0 m-0 rounded"
                            type="submit"
                          >
                            {buttonText}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalInformation;
