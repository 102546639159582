import React, { Component } from "react"
import "./index.css"
import KnowMore from "../../Common/Know-More"

class KnowMoreSection extends Component {
  render() {
    return (
      <section id="know-more">
        <div className="container">
          <KnowMore />
        </div>
      </section>
    )
  }
}

export default KnowMoreSection
