import React, { useContext } from "react";
import HeroSection from "./Hero-Section";
import ProcessSection from "./Process-Section";
import ChooseCar from "./Choose-Car";
import PriceSection from "./Price-Section";
import GuaranteeSection from "./Guarantee-Section";
import KnowMoreSection from "./Know-More-Section";
import TestimonialSection from "../Common/Testimonial-Section/index";
import { LanguageContext } from "../../lang";
import BenefitsSection from "./Benefits-Section";
import OurPartners from "./Our-Partners";
import "./index.css";
import Helmet from "react-helmet";

const Home = () => {
  const {
    translations: { home },
  } = useContext(LanguageContext);

  const {
    howItWorks,
    ourCars,
    paymentMethods,
    fixedPrices,
    taxionspot,
    testimonials,
    benefits,
    ourPartners,
  } = home;
  
  const ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Schnelleintaxi",
    "url": "https://www.schnelleintaxi.de/",
    "logo": "",
    "sameAs": [
      "https://www.facebook.com/Taxionspot/",
      "https://twitter.com/taxionspot/",
      "https://www.instagram.com/taxionspot/",
      "https://www.youtube.com/channel/UC5sHNCp4Jis2mFlORwc3hNw"
    ]
  }

  return (
    <>
      <HeroSection />
      <BenefitsSection benefits={benefits} />
      <PriceSection fixedPrices={fixedPrices} />

      {/* <ProcessSection howItWorks={howItWorks} /> */}
      <ChooseCar ourCars={ourCars} paymentMethods={paymentMethods} />
      <GuaranteeSection taxionspot={taxionspot} />
      <KnowMoreSection />
      <OurPartners ourPartners={ourPartners} />
      {/* <TestimonialSection testimonials={testimonials} /> */}
      <Helmet  defer={false}>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>
    </>
  );
};

export default Home;
