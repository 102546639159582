import React, { Component, useContext, useState, useRef } from "react";
import "./index.css";

export const AnimatedWrapper = ({ index, active, children }) => (
  <div
    style={{
      width: "100%",
      height: index === active ? "auto" : 0,
      transition: "all 0.2s",
      overflow: index === active ? "visible" : "hidden",
      opacity: index === active ? 1 : 0,
    }}
  >
    <div>{children}</div>
  </div>
);

export default ({ children, bottomBar, heading }) => (
  <div className="form-wrap stepper">
    <div className="form-head text-center">
      <h4 className="mb-0 form-heading" style={{ color: "#fff" }}>
        {heading}
      </h4>
    </div>
    <div className="stepper-content">{children}</div>
    {bottomBar && <div className="form-footer stepper-footer" />}
  </div>
);
