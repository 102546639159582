import React, { useContext, useState, useEffect } from "react";
import "./index.css";
import { LanguageContext } from "../../../lang";
import { useQuery } from "@apollo/react-hooks";
import { GET_ESTIMATED_FARE } from "../graphql/queries";
import PeopleIcon from "@material-ui/icons/People";
import { isBrowser } from "../../../utils/general";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import CircularProgress from "@material-ui/core/CircularProgress";
import mini from "./icons/4.png";
import midi from "./icons/5-6.png";
import maxi from "./icons/7-8.png";

const getCar = (p) => {
	if (p <= 4) {
		return mini;
	} else if (p > 4 && p < 7) {
		return midi;
	} else return maxi;
};

const getPricingShift = (dateTime) => {
	console.log("getPricingShift: ", dateTime);
	let d = new Date(dateTime);
	if (d.getHours() >= 23 || d.getHours() <= 6) return "night";
	return "day";
};

const Taxi = ({
	taxiData,
	onClick,
	isSelected,
	roundTrip,
	bookingBenefits,
	setSelected,
	...rest
}) => {
	const { id, name, description, passengers } = taxiData;
	const { pickupLoc, destLoc, pickupDate, returnDate } = rest;
	const { benefit1, benefit2, benefit3, benefit4 } = bookingBenefits;
	
	var variables = {
		data: {
			pickupLoc,
			destLoc,
			taxiTypeId: id,
			roundTrip,
			shift: getPricingShift(pickupDate),
		},
	};
	if (roundTrip) {
		variables.data.roundTripShift = getPricingShift(returnDate);
	}
	const { data, loading } = useQuery(GET_ESTIMATED_FARE, {
		variables,
	});
	useEffect(()=>{
		setSelected();
	}, [data])
	let price = data && data.estimateFair && data.estimateFair.netFare;
	let total = data && data.estimateFair && data.estimateFair.totalFare;
	let discountAmount =
		data && data.estimateFair && data.estimateFair.discountAmount;

	const _onClick = () => {
		if (!data) return;
		let fare = {
			...data.estimateFair,
			netFare: price,
			totalFare: price,
		};
		onClick({ taxiData, estimateFair: fare });
	};

	const Icon = isSelected ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon;
	return (
		<div className="taxi-cards">
			<div className="flex-space-between" id={id} onClick={_onClick}>
				<div className="flex-start cursor-pointer">
					<img
						src={getCar(passengers)}
						alt=""
						className="car-image"
					/>
					<div>
						<div className="flex-space-between">
							<div className="car-name">{name}</div>
							<div className="passengerSection flex-start">
								<PeopleIcon
									fontSize="small"
									className="passengerSection"
								/>
								<div>{passengers}</div>
							</div>
						</div>
						<div className="taxi-description">{description}</div>
					</div>
				</div>

				<div>
					<div style={{ textAlign: "right" }}>
						<Icon style={{ height: "18px" }} />
					</div>
					<div
						style={{
							textAlign: "right",
							fontWeight: "bold",
							fontSize: "13px",
						}}
					>
						{loading ? (
							<CircularProgress size={20} />
						) : (
							data && <>€{price}</>
						)}
						{discountAmount ? (
							<div
								style={{
									color: "#ef3e3e",
									fontSize: 12,
									textDecoration: "line-through",
								}}
							>
								€{total}
							</div>
						) : null}
					</div>
				</div>
			</div>
			<ul>
				<li>{benefit1}</li>
				<li>{benefit2}</li>
				<li>{benefit3}</li>
				<li>{benefit4}</li>
			</ul>
		</div>
	);
};

export default Taxi;
