import React from "react";
import './index.css';
import CheckIcon from '@material-ui/icons/Check';

const BenefitCard = ({mainHeading, paragraph,image,className}) => {
    return (
        <div className="benefit-card">
            <div className="card-content-section">
                <div>
                    <img src={image} alt="" className={className}  width="40" />
                </div>
                <div className="mt-3">
                    <div className="card-heading mb-2">
                        <CheckIcon className="heading-check-icon" />{mainHeading}
                    </div>
                    <div className="paragraph-text">{paragraph}</div>
                </div>
            </div>
        </div>
    )
}

export default BenefitCard;


{/* <div className="main-heading-container">
<CheckIcon />
<div className="card-heading">
    {mainHeading}
</div>
</div> */}