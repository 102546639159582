import React, { Component } from "react";
import "./index.css";
import { navigate } from "gatsby";

const GuaranteeSection = ({ taxionspot }) => {
  const {
    mainHeading,
    subHeading,
    subHeading2,
    para1,
    para2,
    listItem1,
    listItem2,
    listItem3,
    listItem4,
    listItem5,
    listItem6,
    safetyPara,
    btnText,
  } = taxionspot;

  return (
    <section id="guarantee-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 p-4 guarantee-desc-wrap">
            {/* <h1 className="guarantee-h1">{mainHeading}</h1> */}
            <h4 className="guarantee-h4">{subHeading}</h4>
            <p className="guarantee-desc-p mt-3">{para1}</p>
            <p className="guarantee-desc-p mb-4">{para2}</p>
            <p className="guarantee-desc-p">- {listItem1}</p>
            <p className="guarantee-desc-p">- {listItem2}</p>
            <p className="guarantee-desc-p">- {listItem3}</p>
            <p className="guarantee-desc-p">- {listItem4}</p>
            <p className="guarantee-desc-p">- {listItem5}</p>
            <p className="guarantee-desc-p">- {listItem6}</p>
            <h4 className="guarantee-h4">{subHeading2}</h4>
            <p className="guarantee-desc-p mt-3">{safetyPara}</p>
            <span
              onClick={() => navigate("/about")}
              style={{ cursor: "pointer" }}
              className="read-more-link mt-4"
            >
              {btnText}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GuaranteeSection;
