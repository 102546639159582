import React, { Component, useContext } from "react";
import "./index.css";
import { LanguageContext } from "../../../lang";

const KnowMore = () => {
  const {
    translations: { home },
  } = useContext(LanguageContext);

  const {
    wantToKnowMore: { subHeading, mainHeading, para, contacts },
  } = home;

  const { office, phone, email } = contacts;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 text-center">
          <h3 className="know-more-h3">{subHeading}</h3>
          <h2 className="know-more-h2">{mainHeading}</h2>
          <hr className="know-more-head-hr" />
        </div>
      </div>

      <div className="row pt-5">
        <div className="col-12">
          <p className="know-more-text text-center">{para}</p>
        </div>
      </div>

      <div className="row know-more-icons-wrapper">
        <div className="col-lg-4 col-md-12 text-center know-more-icons-wrap know-more-border-right">
          <img
            className="img-fluid know-more-icons-small"
            src={require("../../../images/icons/address.png")}
          />
          <h4 className="know-more-h4 mt-3">{office.name}</h4>
          <p className="know-more-icons-desc">{office.para}</p>
        </div>

        <div className="col-lg-4 col-md-12 text-center know-more-icons-wrap know-more-border-right">
          <img
            className="img-fluid know-more-icons-small"
            src={require("../../../images/icons/phone.png")}
          />
          <h4 className="know-more-h4 mt-3">{phone.name}</h4>
          <a href="tel:0402405999" className="know-more-icons-desc">
            {phone.para}
          </a>
        </div>

        <div className="col-lg-4 col-md-12 know-more-icons-wrap text-center">
          <img
            className="img-fluid know-more-icons-small"
            src={require("../../../images/icons/mail.png")}
          />
          <h4 className="know-more-h4 mt-3">{email.name}</h4>
          <a
            href="mailto: info@taxionspot.com"
            className="know-more-icons-desc"
          >
            {email.para}
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default KnowMore;
