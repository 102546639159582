import React, { useContext, useState, useRef } from "react";
import { Link } from "gatsby";
import "./index.css";
import { useMediaQuery } from "react-responsive";
import { LanguageContext } from "../../../lang";
import RouteSelection from "../../BookingForm/Form-Steps/routeSelections";
import ChooseTaxi from "../../BookingForm/Form-Steps/taxi-cards";
import PersonalInfo from "../../BookingForm/Form-Steps/personalInfo";
import ConfirmBooking from "../../BookingForm/Form-Steps/confirmBooking";
import { Modal } from "reactstrap";
import Stepper, { AnimatedWrapper } from "./stepper";
import CovidPopup from "../Covid-Popup/index";

const HeroSection = () => {
  const {
    translations: { home, personalInfo },
  } = useContext(LanguageContext);
  const {
    getTaxiText,
    welcomeText,
    taxiText,
    contactUsText,
    selectTaxi,
  } = home.heroSection;
  const { formTitle } = personalInfo;
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  const [rideInfo, setRideInfo] = useState({});
  const [showBookingDetail, setShowBookingDetail] = useState(false);
  const [step, setStep] = useState(1);

  const onCloseModal = () => {
    setShowBookingDetail(false);
  };

  const saveRouteInfo = (step1) => {
    setRideInfo({ step1 });
    setStep(step + 1);
  };

  const saveTaxiInfo = (step2) => {
    if (!step2.taxiData) {
      return alert("Please select the taxi");
    }
    setRideInfo({ ...rideInfo, step2 });
    setStep(step + 1);
  };

  const savePersonalInfo = (step4) => {
    setRideInfo({ ...rideInfo, step4 });
    console.log("rideInfo: ", rideInfo);
    setShowBookingDetail(true);
  };

  const goBack = () => {
    setStep(step - 1);
  };

  const getHeading = () => {
    switch (step) {
      case 1:
        return getTaxiText;
      case 2:
        return selectTaxi;
      case 3:
        return formTitle;
      default:
        return "";
    }
  };

  return (
    <>
      <section id="hero-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 mt-auto mb-auto text-center welcome-col">
              <CovidPopup />
              
              {isDesktopOrLaptop && (
                <>
                  <h1 className="hero-head">
                    {welcomeText} <br />
                    <span>{taxiText}</span>
                  </h1>
                  {/* <Link to="/" className="btn contact-btn">
                    {contactUsText}
                  </Link> */}
                </>
              )}
            </div>
            <div className="col-lg-6 col-md-12 mt-auto mb-auto  welcome-col">
              <Stepper heading={getHeading()} bottomBar={step === 1}>
                <AnimatedWrapper index={1} active={step}>
                  <div className="form">
                    <RouteSelection
                      onNext={saveRouteInfo}
                      {...rideInfo.step1}
                    />
                  </div>
                </AnimatedWrapper>
                <AnimatedWrapper index={2} active={step}>
                  <div className="form" style={{ position: "relative" }}>
                    <ChooseTaxi
                      onSelect={saveTaxiInfo}
                      {...rideInfo.step1}
                      onBack={goBack}
                      show={true}
                    />
                  </div>
                </AnimatedWrapper>
                <AnimatedWrapper index={3} active={step}>
                  <PersonalInfo onSelect={savePersonalInfo} onBack={goBack} />
                </AnimatedWrapper>
              </Stepper>
            </div>
          </div>
          <Modal size="md" isOpen={showBookingDetail}>
            <>
              <ConfirmBooking rideInfo={rideInfo} onClose={onCloseModal} />
              <br />
              <br />
              <br />
            </>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
