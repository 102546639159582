import React from "react";
import "./index.css";
import Slider from "react-slick";
import Fontys from "../../../images/Homepage/fontys.png";
import BookingImage from "../../../images/Homepage/booking.png";
import Knaek from "../../../images/Homepage/logo-knaek-studentenkortingsapp.png";
import { Hidden } from "@material-ui/core";

const images = [
  {
    name: Fontys,
  },
  {
    name: BookingImage,
  },
  {
    name: Knaek,
  },
];

const OurPartners = ({ ourPartners }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var responsiveSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
  };

  const { mainHeading } = ourPartners;

  const renderImages = (value) => {
    return <img src={value.name} alt="" className="logo-image" />;
  };

  return (
    <section id="our-partners">
      <div className="partners-h2 text-center pb-4">
        {mainHeading}
        <hr className="hr" />
      </div>
      {/* <Hidden only={["xs"]}>
                <Slider {...settings}>
                    {images.map(renderImages)}
                </Slider>
            </Hidden>
            <Hidden only={["sm","md","lg"]}>
                <Slider {...responsiveSettings}>
                    {images.map(renderImages)}
                </Slider>
            </Hidden> */}
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 slide">
            <Slider {...settings}>{images.map(renderImages)}</Slider>
          </div>
        </div>
      </div>

      {/* <Hidden only={["md", "lg"]}>
          <Slider {...responsiveSettings}>{images.map(renderImages)}</Slider>
        </Hidden> */}
    </section>
  );
};

export default OurPartners;
