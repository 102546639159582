import React from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/SEO/seo";
import Home from "../components/Home/index";
import { useContext } from "react";
import { LanguageContext } from "../lang";

const IndexPage = () => {
	const {
		value
	  } = useContext(LanguageContext);
	let langvar="en";

	return(
		<>
		{langvar==="EN" || "en"?<Layout>
			<SEO
				title="Taxi Kiel | Taxi Hamburg | Taxi Services near Me | VIP Taxi Kiel"
				meta="Taxi Services Kiel | Book Online Taxi Services to Kiel, Near Me"
				// description="hello english"
				description="We provide an affordable yet comfortable taxi service to Kiel and Hamburg at your doorstep with just one click. "
			/>
			<Home />
		</Layout>:
		<Layout>
			<SEO
				title="Taxi Kiel | Taxi Hamburg | Taxi Services near Me | VIP Taxi Kiel"
				meta="Taxi Services Kiel | Book Online Taxi Services to Kiel, Near Me"
				// description="hello german"
				description="Buchen Sie ein Taxi einfach online über schnelleintaxi: • Neumünster-Hamburg • Neumünster-Flughafen Hamburg • Neumünster-Kiel • Kiel-Hamburg • Kiel-Flughafen Hamburg. Sicher & Zuverlassig, buchen Sie jetzt!"
			/>
			<Home />
		</Layout>}
	</>
	)
	
};

export default IndexPage;
