import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import { DateTimePicker } from "../../DateTimePicker";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
const useStyles = makeStyles(theme => ({
  container: {
    width: 300,
    padding: "12px 12px 4px"
  },
  heading: {
    fontWeight: "bold",
    borderBottom: "1px solid #eee",
    paddingBottom: 7
  },
  content: {
    padding: "2px 5px",
    background: "#eee",
    border: "1px solid #d7d5d5",
    marginBottom: 5
  },
  toggler: {
    color: "white",
    padding: 0
  }
}));

export default function SimplePopover({
  label,
  heading,
  placeholder,
  culture,
  btnText,
  handleCheck,
  ...props
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  const [pop, setPop] = React.useState(false);
  const handleClick = event => {
    console.log("clicked");
    setAnchorEl(event.currentTarget);
    setChecked(true);
    setPop(true);
  };

  const handleClose = () => {
    props.form.setFieldValue(props.field.name, "");
    setAnchorEl(null);
    setPop(false);
    setChecked(false);
    handleCheck(false);
  };
  const handlePopClose = () => {
    console.log("popop");
    props.form.setFieldValue(props.field.name, "");
    setAnchorEl(null);
    setPop(false);
  };
  const handleSelect = () => {
    setAnchorEl(null);
    handleCheck(true);
  };

  const onChange = date => {
    props.form.setFieldValue(props.field.name, date);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const Icon = pop ? CheckBoxIcon : CheckBoxOutlineBlankIcon;
  return (
    <div style={{ marginBottom: 20 }}>
      <Button
        aria-describedby={id}
        className={classes.toggler}
        onClick={handleClick}
      >
        <Icon /> &nbsp;&nbsp;{label}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <div className={classes.container}>
          <div className={classes.heading}>{heading}</div>
          <div className={classes.content}>
            <DateTimePicker culture={culture} onChange={onChange} />
          </div>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSelect}
              color="primary"
              autoFocus
              variant="contained"
            >
              {btnText}
            </Button>
          </DialogActions>
        </div>
      </Popover>
    </div>
  );
}