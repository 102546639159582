import React from "react";
import "./index.css";

const PaymentMethods = ({ paymentMethods }) => {
  const { mainHeading, para } = paymentMethods;
  return (
    <section id="payment-section">
      <div className="heading">
        <b>{mainHeading}</b>
      </div>
      <div className="paragraph">{para}</div>
    </section>
  );
};

export default PaymentMethods;
