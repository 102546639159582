import React, {useContext} from "react";
import ErrorIcon from '@material-ui/icons/Error';
import {LanguageContext} from "../../../lang";
import './index.css';

const CovidPopup = () => {
    const {translations: {home},} = useContext(LanguageContext);
    const {para,linkText,culture} = home.covidBanner;
    return (
        <div className="covid-popup">
            <div className="covid-popup-header"><ErrorIcon /> COVID 19</div>
            <div className="paragraph">
                {para}
                <a href={culture === "de" ? "/covid": "/en/covid"} className="link-text">
                    {linkText}
                </a>
            </div>
        </div>
    )
}

export default CovidPopup;