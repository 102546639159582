import React, { Component } from "react";
import PaymentMethodSection from "../Payment-Section/index";
import "./index.css";

const ChooseCar = ({ ourCars, paymentMethods }) => {
  const { subHeading, mainHeading, cars, mostPopular } = ourCars;

  const { firstCar, secondCar } = cars;

  return (
    <section id="choose-car-section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            {/* <h3 className="choose-car-h3">{subHeading}</h3> */}
            <h2 className="choose-car-h2">{mainHeading}</h2>
            <hr className="choose-car-head-hr" />
          </div>
        </div>

        <div className="row mt-5 ">
          <div className="col-md-6 text-center  d-flex justify-content-center">
            <div className="business-car p-4">
              <img
                className="img-fluid"
                src={require("../../../images/Homepage/day.png")}
                alt="Business Car"
              />
              <h4 className="car-type ">{firstCar.name}</h4>
              <section className="car-details mb-3">
                <p className="text-dark text-left py-1 m-0">
                  {firstCar.startPrice}
                  <b>3,50 €</b>
                </p>
                <p className="text-dark text-left py-1 m-0">
                  {firstCar.farePerKm1}
                  <b>2,00 €</b>
                </p>
                <p className="text-dark text-left py-1 m-0">
                  {firstCar.farePerKm2}
                  <b>1,85 €</b>
                </p>
                <p className="text-dark text-left py-1 m-0">
                  {firstCar.farePerKm3}
                  <b>1,55 €</b>
                </p>
                <p className="text-dark text-left py-1 m-0">
                  {firstCar.farePerKm4}
                  <b>36,00 €</b>
                </p>
              </section>
            </div>
          </div>

          <div className="col-md-6 text-center  d-flex justify-content-center">
            <div className="business-car p-4">
              <img
                className="img-fluid pt-lg-3"
                src={require("../../../images/Homepage/night.png")}
                alt="Business Car"
              />
              <h4 className="car-type">{secondCar.name}</h4>
              <p className="car-dsc">{secondCar.dsc}</p>
              <section className="car-details mb-3">
                <p className="text-dark text-left py-1 m-0">
                  {secondCar.startPrice}
                  <b>3,50 €</b>
                </p>
                <p className="text-dark text-left py-1 m-0">
                  {secondCar.farePerKm1}
                  <b>2,10 €</b>
                </p>
                <p className="text-dark text-left py-1 m-0">
                  {secondCar.farePerKm2}
                  <b>1,90 €</b>
                </p>
                <p className="text-dark text-left py-1 m-0">
                  {secondCar.farePerKm3}
                  <b>1,65 €</b>
                </p>
                <p className="text-dark text-left py-1 m-0">
                  {secondCar.farePerKm4}
                  <b>36,00 €</b>
                </p>
              </section>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12 col-sm-12 col-xs-12 col-lg-10 payment-section">
            <PaymentMethodSection paymentMethods={paymentMethods} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseCar;
