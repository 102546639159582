import React, { useContext, useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import "./index.css";
import SuggestLocation from "./../../Common/SuggestLocation";
import * as Yup from "yup";
import { LanguageContext } from "../../../lang";
import moment from "moment";
import { Button } from "reactstrap";
import NoOfPassenger from "./passenger";
import ReturnDateTimePicker from "./return-date-time";
import { AdvanceDateTimePicker } from "../../DateTimePicker";

var localization = require("moment/locale/nl");

const DateTimePicker = (props) => {
	const onChange = (data) => {
		props.form.setFieldValue(props.field.name, data);
	};
	return <AdvanceDateTimePicker onChange={onChange} {...props} />;
};

const RouteSelection = ({ onNext, handleDiscount }) => {
	const [retour, setretour] = useState(false);
	const {
		translations: { home },
	} = useContext(LanguageContext);
	const {
		pickUpPlaceHolder,
		destinationPlaceHolder,
		noPlaceHolder,
		nextOneText,
		returnText,
		returnDateTimeText,
		returnPlaceholder,
	} = home.routeSelection;
	const { culture, selectText } = home.dateTimePicker;

	const schema = Yup.object().shape({
		picklocation: Yup.object()
			.shape({
				address: Yup.string().required("Adress Required"),
				value: Yup.string().required("Adress Required"),
			})
			.required("Adress Required"),
		droplocation: Yup.object().shape({
			address: Yup.string().required("Adress Required"),
			value: Yup.string().required("Adress Required"),
		}),
		pickupDate: Yup.object().shape({
			date: Yup.date().required("Pickup Date Time is Required"),
			rideType: Yup.string().required(),
		}),
		noOfPassengers: Yup.number().required("No. Of Passenger Required"),
	});
	const handleChange = (e) => {
		console.log({ next: onNext });
		handleDiscount(!e);
	};
	const handleCheck = (e) => {
		setretour(e);
		console.log(e);
	};
	const _handleSubmit = (values) => {
		const {
			picklocation: {
				value: pickupLoc,
				coordinates: { lat: pickupCoordinateY, lng: pickupCoordinateX },
			},
			droplocation: {
				value: destLoc,
				coordinates: { lat: destCoordinateY, lng: destCoordinateX },
			},
			pickupHouseNo,
			dropHouseNo,
			roundTrip,
			pickupDate,
			noOfPassengers,
		} = values;
		console.log({ roundTrip: retour });
		onNext({
			pickupDate: pickupDate.date,
			pickupDateForText: moment(pickupDate.date)
				.locale("de", localization)
				.format("dddd, Do MMMM YYYY, HH:mm"),
			rideType: pickupDate.rideType,
			pickupLoc: `${pickupLoc}${
				pickupHouseNo ? ", Nr." + pickupHouseNo : ""
			}`,
			pickupCoordinateY,
			pickupCoordinateX,
			destLoc: `${destLoc}${dropHouseNo ? ", Nr." + dropHouseNo : ""}`,
			destCoordinateX,
			destCoordinateY,
			roundTrip: retour,
			...(retour
				? {
						returnDateForText: moment(roundTrip)
							.locale("de", localization)
							.format("dddd, Do MMMM YYYY, HH:mm"),
				  }
				: {}),

			returnDate: roundTrip || "",
			noOfPassengers,
		});
	};

	return (
		<React.Fragment>
			<Formik
				initialValues={{
					picklocation: "",
					droplocation: "",
					dropHouseNo: "",
					pickupHouseNo: "",
					roundTrip: false,
					noOfPassengers: 1,
					pickupDate: "",
				}}
				validationSchema={schema}
				onSubmit={_handleSubmit}
			>
				{({
					errors,
					touched,
					setFieldValue,
					values,
					handleSubmit,
					onBack,
				}) => (
					<Form className="route-selection-form">
						<div>
							<div className="input-group field-container location-input-container">
								<Field
									type="text"
									name="picklocation"
									className="form-control border-left-0 form-padding border-radius"
									placeholder={pickUpPlaceHolder}
									markerColor="#feb532"
									component={SuggestLocation}
									hKey="pickupHouseNo"
									hPlaceholder={noPlaceHolder}
									handleChange={handleChange}
								/>
								{errors.picklocation && touched.picklocation ? (
									<div className="bg-danger my-1 p-0 col-12 rounded">
										<p className="text-white p-1 m-0 error-text">
											{errors.picklocation.address}
										</p>
									</div>
								) : null}
							</div>

							<div className="input-group field-container location-input-container mt-10">
								<Field
									type="text"
									name="droplocation"
									className="form-control border-left-0 form-padding border-radius"
									placeholder={destinationPlaceHolder}
									component={SuggestLocation}
									hKey="dropHouseNo"
									hPlaceholder={noPlaceHolder}
									markerColor="red"
									handleChange={handleChange}
								/>
								{errors.droplocation && touched.droplocation ? (
									<div className="bg-danger my-1 p-0 col-12 rounded">
										<p className="text-white p-1 m-0 error-text">
											{errors.droplocation.address}
										</p>
									</div>
								) : null}
							</div>
						</div>
						<div style={{ display: "flex" }} className="mt-10">
							<Field
								type="text"
								name="pickupDate"
								className="form-control border-left-0"
								component={DateTimePicker}
							/>
							<Field
								type="text"
								name="noOfPassengers"
								className="form-control border-left-0"
								component={NoOfPassenger}
							/>
						</div>
						<div className="mt-10">
							<Field
								name="roundTrip"
								component={ReturnDateTimePicker}
								label={returnText}
								heading={returnDateTimeText}
								placeholder={returnPlaceholder}
								culture={culture}
								btnText={selectText}
								handleCheck={handleCheck}
							/>
						</div>

						<div>
							<Button className="next-one-button" type="submit">
								<div
									style={{ fontSize: 14, fontWeight: "500" }}
								>
									{nextOneText}
								</div>
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};

export default RouteSelection;
