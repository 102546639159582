import React, { useState, useContext, useEffect } from "react";
import { LanguageContext } from "../../../lang";
import "./index.css";
import { useQuery } from "@apollo/react-hooks";
import { GET_TAXIES } from "../graphql/queries";
import Spinner from "../../Common/Spinner";
import Taxi from "./taxi-detail-card";

const style = {
	footer: {
		display: "flex",
		justifyContent: "space-between",
		padding: 20,
	},
	select: {
		width: 200,
	},
	back: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		color: "white",
	},
};

const Footer = ({ onBack, onSelect, rightText, leftText, ...rest }) => {
	return (
		<div style={style.footer}>
			<div style={style.back} onClick={onBack}>
				<i
					className="fa fa-arrow-left ts-form-back-arrow"
					style={{
						color: "white",
						background: "transparent",
					}}
				></i>
				<span className="return-btn-text">{leftText}</span>
			</div>
			{onSelect && (
				<button
					className="ts-form-next-link py-2 text-white border-0 m-0 rounded"
					style={style.select}
					onClick={onSelect}
				>
					{rightText}
				</button>
			)}
		</div>
	);
};

const TaxiCards = ({
	showTaxiDetail,
	onSelect,
	onBack,
	noOfPassengers,
	show,
	...rest
}) => {
	const {
		translations: { selectCar, bookingSteps, bookingBenefits },
	} = useContext(LanguageContext);
	const { notFound } = selectCar;
	const { back1, next1 } = bookingSteps;
	const [selected, setSelected] = useState({});
	const { loading, data } = useQuery(GET_TAXIES);

	console.log("SELECCTED :", selected);

	const _onSelect = () => {
		let f = data.taxies.filter((i) => i.passengers >= noOfPassengers);
		if (f.length === 0) return;
		onSelect({ ...selected, noOfPassengers });
	};

	if ((loading || !show) && !data) return <Spinner />;

	let taxies = data
		? data.taxies.filter((i) => i.passengers >= noOfPassengers)
		: [];
	if (!taxies || !taxies.length)
		return (
			<>
				<h4 className="text-center no-taxies-text">{notFound}</h4>
				<Footer onBack={onBack} />
			</>
		);

	return (
		<>
			<div className="taxi-card-section">
				{taxies.map((taxi, idx) => (
					<Taxi
						taxiData={taxi}
						key={idx}
						bookingBenefits={bookingBenefits}
						onClick={setSelected}
						setSelected={setSelected}
						isSelected={
							selected &&
							selected.taxiData &&
							selected.taxiData.id === taxi.id
						}
						noOfPassengers={noOfPassengers}
						{...rest}
					/>
				))}
			</div>
			<Footer
				onBack={onBack}
				onSelect={_onSelect}
				rightText={next1}
				leftText={back1}
			/>
		</>
	);
};

export default TaxiCards;
