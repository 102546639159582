import React from "react";
import CheckIcon from '@material-ui/icons/Check';
import BenefitCard from "./Benefit-Card";
import { Grid, Hidden } from "@material-ui/core"; 
import PathIcon from "../../../images/Homepage/path.png";
import ShareIcon from "../../../images/Homepage/share.png";
import CalendarIcon from "../../../images/Homepage/calendar.png";
import NetherlandMapIcon from "../../../images/Homepage/netherlands.png";
import CoronaFreeIcon from "../../../images/Homepage/corona-free.png";
import './index.css';

const Icon = () => {
    return (
        <div className="check-icon-container">
            <CheckIcon className="check-icon" />
        </div>
    )
}

const MobileViewHeader = ({benefit}) => {
    return (
        <Hidden only={["lg","xl"]}>
        <div className="benefits-header">
            <div className="icon-text-container">
                <Icon />{benefit}
            </div>
        </div>
        </Hidden>
    )
}

const BenefitsSection = ({benefits}) => {
    const {benefit1, benefit2, benefit3, benefit4, mainHeading1,
      mainHeading2, mainHeading3, mainHeading4, para1, para2, para3, para4 } = benefits;
    return (
        <div id="benefits">
            <Hidden only={["xs","sm","md"]}>
            <div className="benefits-header">
                <div className="icon-text-container">
                   <Icon />{benefit1}
                </div>
                <div className="icon-text-container">
                    <Icon />{benefit2}
                </div>
                <div className="icon-text-container">
                    <Icon />{benefit3}
                </div>
                <div className="icon-text-container">
                    <Icon />{benefit4}
                </div>
            </div>
            </Hidden>
            <Grid container className="benefit-card-container">
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <MobileViewHeader benefit={benefit1} />
                    <BenefitCard mainHeading={mainHeading1} paragraph={para1} 
                    className="path-image" image={PathIcon} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <MobileViewHeader benefit={benefit2} />
                    <BenefitCard mainHeading={mainHeading2} paragraph={para2} 
                    className="share-icon" image={ShareIcon} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <MobileViewHeader benefit={benefit3} />
                    <BenefitCard mainHeading={mainHeading3} paragraph={para3} 
                    className="calendar-icon" image={CalendarIcon} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <MobileViewHeader benefit={benefit4} />
                    <BenefitCard mainHeading={mainHeading4} paragraph={para4} 
                    className="corona-free" image={CoronaFreeIcon} />
                </Grid>
            </Grid>
        </div>
    )
}

export default BenefitsSection;