import gql from "graphql-tag";

export const GET_TAXIES = gql`
  query getTaxies {
    taxies {
      id
      name
      description
      passengers
    }
  }
`;

export const GET_ESTIMATED_FARE = gql`
  query getEstimatedFare($data: EstimateFairInput!) {
    estimateFair(data: $data) {
      netFare
      distanceKM
      startPrice
      discountAmount
      discountPercent
      totalFare
      distanceKM
      estimatedTimeInMins
      priceKm
      priceMin
      driverEarn
    }
  }
`;
export const GET_SETTING = gql`
  query getSettings($data: String!) {
    setting(id: $data) {
      id
      timeP1
      timeP2
      timeP3
      nearDriverRadius
      onlinePaymentDiscount
    }
  }
`;

export const GET_RIDE_STATUS = gql`
  query($id: String!) {
    ride(id: $id) {
      status
    }
  }
`;
export default {
  GET_ESTIMATED_FARE,
  GET_TAXIES,
  GET_RIDE_STATUS,
};
