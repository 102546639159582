import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../../../lang";
export default (props) => {
	const {
		translations: { home },
	} = useContext(LanguageContext);
	const { noOfPassengersText } = home.routeSelection;
	const [noOfPassengers, setNoOfPassengers] = useState(1);

	const handleIncrement = () => {
		if (noOfPassengers === 8) return;
		setNoOfPassengers(noOfPassengers + 1);
		props.form.setFieldValue(props.field.name, noOfPassengers + 1);
	};

	const handleDecrement = () => {
		if (noOfPassengers === 1) return;
		setNoOfPassengers(noOfPassengers - 1);
		props.form.setFieldValue(props.field.name, noOfPassengers - 1);
	};

	if (props.customRender)
		return props.customRender(
			noOfPassengersText,
			noOfPassengers,
			handleDecrement,
			handleIncrement
		);
	return (
		<div
			className="passengers-section flex-space-between"
			style={{ width: "100%" }}
		>
			<div className="col-6">
				<div className="passengersText">{noOfPassengersText}</div>
			</div>
			<div className="col-6">
				<div className="flex-space-between">
					<div
						className="decreaseIcon iconSize cursor-pointer"
						onClick={handleDecrement}
					>
						<i
							className="fa fa-minus-circle"
							aria-hidden="true"
						></i>
					</div>
					<div className="no-passengers-text">{noOfPassengers}</div>
					<div
						className="increaseIcon iconSize cursor-pointer"
						onClick={handleIncrement}
					>
						<i className="fa fa-plus-circle" aria-hidden="true"></i>
					</div>
				</div>
			</div>
		</div>
	);
};
