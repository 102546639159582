import React, { Component, useContext } from "react";
import "./index.css";

const PriceSection = ({ fixedPrices }) => {
  const { plans, mainHeading } = fixedPrices;
  const { firstPlan, secondPlan, thirdPlan, fourthPlan, fifthPlan } = plans;

  return (
    <section id="price-section">
      <div className="container">
        <div className="row price-section-container">
          <div className="col-12">
            <h2 className="plansHeading">{mainHeading}</h2>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="price-bg text-center">
              <img
                className="img-fluid price-top-icon"
                src={require("../../../images/icons/split.png")}
              />
              <h3 className="route">{firstPlan.name}</h3>
              <h4 className="price">{firstPlan.price}</h4>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 text-center">
            <div className="price-bg text-center">
              <img
                className="img-fluid price-top-icon"
                src={require("../../../images/icons/split.png")}
              />
              <h3 className="route">{secondPlan.name}</h3>
              <h4 className="price">{secondPlan.price}</h4>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 text-center">
            <div className="price-bg text-center">
              <img
                className="img-fluid price-top-icon"
                src={require("../../../images/icons/split.png")}
              />
              <h3 className="route">{thirdPlan.name}</h3>
              <h4 className="price">{thirdPlan.price}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 text-center">
            <div className="price-bg text-center">
              <img
                className="img-fluid price-top-icon"
                src={require("../../../images/icons/split.png")}
              />
              <h3 className="route">{fourthPlan.name}</h3>
              <h4 className="price">{fourthPlan.price}</h4>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 text-center">
            <div className="price-bg text-center">
              <img
                className="img-fluid price-top-icon"
                src={require("../../../images/icons/split.png")}
              />
              <h3 className="route">{fifthPlan.name}</h3>
              <h4 className="price">{fifthPlan.price}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PriceSection;
